<template>
  <div>
    <h1 class="unicorn-h1 mt-4">Мониторинг</h1>
    <h4 class="unicorn-h4 mb-8">Выбор класса</h4>

    <core-loading v-if="loading" />

    <div v-else-if="error">
      <u-alert variant="danger" icon="mdi-alert">
        <template #title>
          Ошибка!
        </template>

        <p>
          Ошибка загрузки данных. Попробуйте позднее или перезагрузить страницу.
        </p>
      </u-alert>
    </div>

    <div v-else v-for="(classes, key) in dataset" :key="key" class="mb-8">
      <h4 class="mb-4 unicorn-h4">{{ key }}-е классы</h4>
      <u-divider />

      <div class="-mx-2 -mt-2">
        <u-button
          @click="
            $router.push({
              name: 'class-monitoring',
              params: { classId: cl.id },
              query: { liter: cl.liter, number: cl.number }
            })
          "
          class="mx-2 my-2"
          v-for="cl in classes"
          :key="cl.id"
        >
          {{ cl.number }} {{ cl.liter }}
        </u-button>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../services/Api";
import { groupBy } from "lodash";

export default {
  name: "Monitoring",
  data() {
    return {
      dataset: [],
      loading: true
    };
  },

  async mounted() {
    try {
      this.loading = true;
      this.error = false;
      const response = await apiService.monitoring.getClasses();
      this.dataset = groupBy(response.data, "number");
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
};
</script>

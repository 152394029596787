<template>
  <div>
    <h4 class="unicorn-h4 mt-4">
      Мониторинг
    </h4>
    <h1 class="unicorn-h1">
      {{ $route.query.number }}{{ $route.query.liter }} класс
    </h1>

    <div class="my-8">
      <div
        class="text-lg font-medium flex flex-col md:flex-row md:items-center"
      >
        <p class="text-lg mr-4">
          Выберите дату
        </p>

        <u-date-picker
          class="md:ml-4"
          v-model="date"
          title-position="left"
          :max-date="maxDate"
          :model-config="modelConfig"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <u-button block v-on="inputEvents">
              {{ inputValue ? `${inputValue}` : "Выбор даты" }}
            </u-button>
          </template>
        </u-date-picker>
      </div>

      <core-loading v-if="loading" />

      <u-alert v-else-if="error" variant="danger" icon="mdi-alert">
        <template #title>
          Ошибка!
        </template>

        <p>
          Ошибка загрузки данных. Попробуйте позднее или перезагрузить страницу.
        </p>
      </u-alert>

      <div v-else>
        <u-divider />
        <u-hide-at breakpoint="md">
          <div class="grid grid-cols-1 gap-4">
            <u-collapse v-for="(data, index) in dataset" :key="index">
              <template #header>
                <div class="flex justify-between">
                  <div class="">
                    <p class="text-lg font-medium leading-none text-gray-700">
                      {{ data.surname }} {{ data.name }}
                    </p>
                    <span class="text-gray-400 text-xs">
                      Нажмите для детализации
                    </span>
                  </div>

                  <p class="font-medium">
                    <u-icon small icon="mdi-thermometer" />
                    <span
                      v-if="data.sensorValue"
                      :class="{ 'text-red-500': data.sensorValue > 369 }"
                    >
                      {{ (data.sensorValue / 10).toFixed(1) }} ℃
                    </span>
                    <span v-else>
                      ---
                    </span>
                  </p>
                </div>
              </template>

              <p class="text-xs font-medium">Первая регистрация</p>
              <div class="text-gray-400" v-if="data.firstType === null">
                Нет данных
              </div>

              <div class="flex items-center justify-between" v-else>
                <p class="text-gray-700 font-medium">
                  {{ data.firstType === "out" ? "Выход" : "Вход" }}
                </p>
                <p class="text-purple-800 mt-1">
                  {{ formatDate(data.firstDate) }}
                </p>
              </div>

              <u-divider />

              <p class="text-xs font-medium">Последняя регистрация</p>
              <div class="text-gray-400" v-if="data.lastType === null">
                Нет данных
              </div>

              <div class="flex items-center justify-between" v-else>
                <p class="text-gray-700 font-medium">
                  {{ data.lastType === "out" ? "Выход" : "Вход" }}
                </p>
                <p class="text-purple-800 mt-1">
                  {{ formatDate(data.lastDate) }}
                </p>
              </div>

              <u-divider />

              <p class="text-xs font-medium">Замер температуры</p>
              <div class="text-gray-400" v-if="data.sensorDate === null">
                Нет измерений
              </div>

              <div class="flex items-center justify-between" v-else>
                <p class="font-medium">
                  <u-icon small icon="mdi-thermometer" />
                  <span :class="{ 'text-red-500': data.sensorValue > 369 }">
                    {{ (data.sensorValue / 10).toFixed(1) }} ℃
                  </span>
                </p>

                <p class="text-purple-800">
                  {{ formatDate(data.sensorDate) }}
                </p>
              </div>
            </u-collapse>
          </div>
        </u-hide-at>

        <u-show-at breakpoint="md">
          <u-simple-table>
            <thead>
              <th>#</th>
              <th>Фамилия Имя</th>
              <th><u-icon icon="mdi-thermometer" /></th>
              <th>Первая регистрация</th>
              <th>Последняя регистрация</th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in dataset" :key="index">
                <td>{{ index + 1 }}</td>

                <td>{{ data.surname }} {{ data.name }}</td>

                <td>
                  <div class="text-gray-400" v-if="data.sensorDate === null">
                    Нет измерений
                  </div>

                  <div v-else>
                    <p
                      class="font-medium"
                      :class="{
                        'text-red-500': data.sensorValue > 369,
                        'text-green-500': data.sensorValue <= 369
                      }"
                    >
                      {{ (data.sensorValue / 10).toFixed(1) }} ℃
                    </p>

                    <p class="text-purple-800 font-medium">
                      {{ formatDate(data.sensorDate) }}
                    </p>
                  </div>
                </td>

                <td>
                  <div class="text-gray-400" v-if="data.firstType === null">
                    Нет данных
                  </div>

                  <div v-else>
                    <p class="text-xs text-gray-700 font-medium">
                      {{ data.firstType === "out" ? "Выход" : "Вход" }}
                    </p>
                    <p class="text-purple-800 font-medium">
                      {{ formatDate(data.firstDate) }}
                    </p>
                  </div>
                </td>

                <td>
                  <div class="text-gray-400" v-if="data.lastType === null">
                    Нет данных
                  </div>

                  <div v-else>
                    <p class="text-xs text-gray-700 font-medium">
                      {{ data.lastType === "out" ? "Выход" : "Вход" }}
                    </p>
                    <p class="text-purple-800 font-medium">
                      {{ formatDate(data.lastDate) }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </u-simple-table>
        </u-show-at>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/app/services/Api";
import CoreLoading from "@/app/shared/core/CoreLoading";

export default {
  name: "Monitoring",
  components: { CoreLoading },
  data() {
    return {
      dataset: [],
      date: "",
      loading: false,
      error: false,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD" // Uses 'iso' if missing
      },
      maxDate: ""
    };
  },

  watch: {
    async date() {
      try {
        this.loading = true;
        this.error = false;
        await this.loadData();
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  },

  computed: {
    classId() {
      return this.$route.params.classId;
    }
  },

  async mounted() {
    try {
      this.loading = true;
      this.error = false;
      await this.getDate();
      await this.loadData();
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    formatDate(date) {
      const formatDate = new Date(new Date(date).setHours(-9));
      return formatDate.toLocaleTimeString("ru-RU");
    },

    async loadData() {
      const response = await Api.monitoring.getLearners(
        this.classId,
        this.date
      );
      this.dataset = response.data;
    },
    async getDate() {
      const { data: timestamps } = await Api.time();

      const date = new Date(timestamps);
      const stringDate = date
        .toLocaleDateString("ru-RU")
        .split(".")
        .reverse()
        .join("-");

      this.date = stringDate;
      this.maxDate = stringDate;
    }
  }
};
</script>

<template lang="pug">
div
  h1.unicorn-h1.mt-4 Заявка восстановления

  .flex.my-8(class="flex-col md:flex-row md:items-center")
    | Выбор даты
    u-date-picker(
      class="md:ml-4 md:mt-0"
      v-model="date"
      :min-date="lastDate"
      :max-date="currentDate"
      title-position="left"
      :model-config="modelConfig"
    )
      template(v-slot="{ inputValue, inputEvents }")
        u-button(block v-on="inputEvents") {{ inputValue ? `${inputValue}` : "Выбор даты" }}

  u-alert(variant="danger" icon="mdi-alert")
    template(#title) Внимание!
    p Эта страница используется ТОЛЬКО для созданния заявок восстановления!
    p Не используйте ее для создания заявок на текущий день!
    p.mt-2.font-medium Заявки восстановления могут быть созданы только для текущего месяца!


  Request.my-8.border-red-400(
    v-if="date",
    :learners="learners",
    :date="date",
    @close-request="closeRequestConfirm"
  )

  u-dialog(persistent width="360" v-model="confirm")
    template
      u-card
        u-card-title Отправка заявки
        u-card-body
          u-alert(variant="danger") Внимание! Это действие нельзя отменить после подтверждения
          p.mt-4.font-medium Вы отправляете заявку в столовую:
          table.mt-2
            tr(v-for="entry in waitingRequest.stats", :key="entry.id")
              td {{ entry.name }}
              td: span.ml-2.text-red-600 {{ entry.count }} шт.
        u-card-actions
          u-spacer
          u-button.mr-2(@click="confirm = false" variant="secondary" text) Отмена
          u-button(@click="closeRequest" variant="danger") Продолжить

  offline(v-if="offlineError", @close="offlineError = false")
</template>

<script>
import { sortBy } from "lodash";
import Api from "../services/Api";
import Offline from "@/app/shared/components/Offline";

export default {
  components: { Offline },

  data() {
    return {
      learners: [],
      request: {},
      foodSets: [],
      socialGroups: [],

      offlineError: false,

      confirm: false,
      positionsCount: {},
      waitingRequest: { classId: null, positions: null, date: null },

      date: "",

      currentDate: "",
      lastDate: "",

      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD" // Uses 'iso' if missing
      }
    };
  },

  watch: {
    async date() {
      const { classId } = this.$route.params;
      await this.loadEntrants(classId, this.date);
    }
  },

  async mounted() {
    await this.loadServerTime();
    await this.startAutoUpdate();
  },

  beforeDestroy() {
    this.stopAutoUpdate();
  },

  methods: {
    async startAutoUpdate() {
      const { classId } = this.$route.params;
      await this.stopAutoUpdate();
      this.autoUpdateId = setInterval(() => {
        this.getServerTime(classId);
      }, 5000);
    },

    async stopAutoUpdate() {
      clearInterval(this.autoUpdateId);
    },

    async getServerTime() {
      await Api.time();
    },

    async loadServerTime() {
      const { data: timestamp } = await Api.time();
      const date = new Date(timestamp);

      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      this.currentDate = this.formatDate(day - 1, month, year);
      this.lastDate = this.formatDate(1, month, year);
    },

    formatDate(day, month, year) {
      return new Date(year, month, day)
        .toLocaleDateString()
        .split(".")
        .reverse()
        .join("-");
    },

    async loadEntrants(classId, date) {
      const { data: learners } = await Api.classes.getClassLearners(
        classId,
        date
      );
      this.learners = sortBy(learners, ["surname", "name"]);
    },

    async closeRequestConfirm(req) {
      this.confirm = true;
      this.waitingRequest = req;
    },

    async closeRequest() {
      const { classId, body } = this.waitingRequest;

      try {
        await Api.requests.closeRequest(classId, body, this.date);
        await this.loadEntrants(classId);
      } catch (error) {
        const { status } = (error && error.response) || { status: error };
        alert(this.getRequestCloseErrorDesc(status));
      } finally {
        this.confirm = false;
      }

      this.waitingRequest = { classId: null, body: null };
    },

    getRequestCloseErrorDesc(httpErrorCode) {
      switch (httpErrorCode) {
        case 402:
          return "Недостаточно средств. Попробуйте обновить сраницу (клавиша F5)";
        case 404:
          return "Заявка не найдена. Попробуйте перезагрузить страницу (клавиша F5)";
        case 422:
          return "Ошибка в 'Своем заказе'";
        case 409:
          return "Повторное оформление заявки";
        case 500:
          return "Ошибка сервера. Попробуйте позднее";
        case 502:
          return "Тех. работы на сервере. Попробуйте через 5 минут";
        default:
          this.offlineError = true;
          return "Неопознанная ошибка: " + httpErrorCode;
      }
    }
  }
};
</script>
